<template>
	<div class="section">
		<div class="container">
			<div class="section__wrap">
				<div class="section__cnt">
					<div class="section__head section__head--table">
						<div class="title title--section">Ваші колоди</div>
					</div>

					<div class="section__main">

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'pack-index'
	};
</script>

<style scoped lang=scss>

</style>
